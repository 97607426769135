import { render, staticRenderFns } from "./Product-Quantity-Adjustments.vue?vue&type=template&id=202ca7b3&scoped=true&"
import script from "./Product-Quantity-Adjustments.vue?vue&type=script&lang=js&"
export * from "./Product-Quantity-Adjustments.vue?vue&type=script&lang=js&"
import style0 from "./Product-Quantity-Adjustments.vue?vue&type=style&index=0&id=202ca7b3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "202ca7b3",
  null
  
)

export default component.exports