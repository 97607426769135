<template>
  <!-- <h5 class="mb-0 px-4 py-3">Charts</h5>
    <v-divider class="my-0"></v-divider> -->

  <v-row class="px-5 product-row">
   
    <v-col md="12" class="" v-if="parentdetails.adjustment.length">
      <v-simple-table
        fixed-header
        style="height: calc(100vh - 280px)"
        class="bt-table product-simple-table"
      >
        <template v-slot:default>
          <thead style="border: 2px solid #f4f4f4; position: relative; top: 0">
            <tr>
              <th
                v-for="(supplier_h, index) in supplierTableHeaders"
                :key="'$_' + supplier_h.title + '_' + index + 1 + supplier_h.heading"
                :style="{ maxWidth: `${supplier_h.width}px` }"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center" v-on="on" v-bind="attrs">
                      <template v-if="supplier_h.heading === 'primary_sales_price'">
                        <v-icon color="blue">mdi-circle</v-icon>
                      </template>
                      <template v-if="supplier_h.heading === 'secondary_purchase_price'">
                        <v-icon color="red">mdi-circle</v-icon>
                      </template>
                      <p class="text-truncate mb-0">{{ supplier_h.title }}</p>
                    </div>
                  </template>
                  <span class="h5 text-center">{{ supplier_h.title }}</span>
                </v-tooltip>
              </th>
            </tr>
          </thead>

          <tbody v-if="!pageLoading">
            <template v-if="mod_Suppliers.length">
              <tr
                :class="index % 1 === 0 && '#fff'"
                v-for="(row, key, index) in parentdetails.adjustment"
                class="product-listing-tr"
                :key="'k_' + index"
              >
                <td
                  style="border-right: none !important"
                  :width="data_headings.width"
                  v-for="(data_headings, idx) in supplierTableHeaders"
                  :key="data_headings.heading + '_' + idx + '_' + idx * 5"
                  class="px-2"
                >
                  <div
                    class="d-flex justify-center align-center"
                    v-if="data_headings.heading === 'supplier_status'"
                  >
                    <v-switch
                      color="green"
                      :value="false"
                      :ripple="false"
                      hide-details
                      inset
                      disabled="true"
                      class="mt-0"
                      v-model="row[data_headings.heading]"
                    ></v-switch>
                  </div>
                  <div
                    class="d-flex justify-center align-center"
                    v-else-if="
                      data_headings.heading === 'primary_sales_price' ||
                      data_headings.heading === 'secondary_purchase_price'
                    "
                  >
                    {{ formatMoneyVal(row[data_headings.heading]) }}
                  </div>
                  <p v-else class="mb-0 h5 px-6">
                    <template v-if="data_headings.heading == 'current_primary'">
                      <div class="d-flex align-center">
                                          <v-chip color="blue" class="mr-2 white--text px-2" small
                                            >P</v-chip
                                          >
                                          <span class="font-weight-bold ml-2">{{ row.current_primary }}</span>
                                          <span class="font-weight-bold ml-2">{{ row.primary_sales_uom }}</span>
                                        </div>
                                        <div class="d-flex align-center pt-2">
                                          <v-chip color="red" class="mr-2  white--text px-2" small
                                            >S</v-chip
                                          >
                                          <span class="font-weight-bold ml-2">{{ row.current_secondary }}</span>
                                          <span class="font-weight-bold ml-2">{{ row.secondary_purchase_uom }}</span>
                                        </div>
                      <!-- {{ row[data_headings.heading] + " " + row["primary_sales_uom"] }} -->
                    </template>
                    <template v-else-if="data_headings.heading == 'type'">
                      <v-chip color="green" class="mr-2 white--text px-2" small
                                            >                      {{ row[data_headings.heading]}}
</v-chip
                                          >
                    </template>
                    <template v-else-if="data_headings.heading == 'quantity'">
                      <div class="d-flex align-center">
                                          <v-chip color="blue" class="mr-2 white--text px-2" small
                                            >P</v-chip
                                          >
                                          <span v-if="row.quantity_primary" class="font-weight-bold ml-2">{{ row.quantity_primary }}</span>
                                          <span v-else class="font-weight-bold ml-2">0</span>
                                          <span class="font-weight-bold ml-2">{{ row.primary_sales_uom }}</span>
                                        </div>
                                        <div class="d-flex align-center pt-2">
                                          <v-chip color="red" class="mr-2 white--text px-2" small
                                            >S</v-chip
                                          >
                                          <span v-if="row.quantity_secondary" class="font-weight-bold ml-2">{{ row.quantity_secondary }}</span>
                                          <span v-else class="font-weight-bold ml-2">0</span>

                                          <span class="font-weight-bold ml-2">{{ row.secondary_purchase_uom }}</span>
                                        </div>
                    </template>
                    <template v-else-if="data_headings.heading == 'serial_no'">
                      {{ row[data_headings.heading] }}
                    </template>
                    <template v-else-if="data_headings.heading == 'available_secondary'">
                      <div class="d-flex align-center">
                                          <v-chip color="blue" class="mr-2 white--text px-2" small
                                            >P</v-chip
                                          >
                                          <span class="font-weight-bold ml-2">{{ row.available_primary }}</span>
                                          <span class="font-weight-bold ml-2">{{ row.primary_sales_uom }}</span>
                                        </div>
                                        <div class="d-flex align-center pt-2">
                                          <v-chip color="red" class="mr-2 white--text px-2" small
                                            >S</v-chip
                                          >
                                          <span class="font-weight-bold ml-2">{{ row.available_secondary }}</span>
                                          <span class="font-weight-bold ml-2">{{ row.secondary_purchase_uom }}</span>
                                        </div>
                    </template>
                    <template v-else-if="data_headings.heading == 'date'">
                      {{ formatedateTime(row[data_headings.heading]) }}
                    </template>
                    <span v-if="row[data_headings.title.toLowerCase() + '_uom']">
                      / {{ row[data_headings.title.toLowerCase() + "_uom"] }}
                    </span>
                  </p>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    <v-col v-else md="12" class="text-center mt-4">
      <p class="m-0 text-center black--text text-h6">
        <img
          width="30"
          :src="$assetURL('media/error/empty.png')"
          class="row-not-found-image mr-4"
        />
        Uhh... There are no data available at the moment.
      </p>
    </v-col>
  </v-row>
</template>

<script>
import { formatMoney } from "accounting-js";
import moment from "moment-timezone";

export default {
  name: "product-chart",
  title: "Chart Product",
  props: {
    parentdetails: {
      type: Object,
      default: () => {
        return [];
      },
    },
  },

  data() {
    return {
      supplierTableHeaders: [
        { title: "Date", heading: "date", width: 150, uom: "" },

        { title: "Current Stock", heading: "current_primary", width: 150, uom: "" },
        { title: "Type", heading: "type", width: 150, uom: "" },
        { title: "Serial No", heading: "serial_no", width: 150, uom: "" },
        { title: "Quantity", heading: "quantity", width: 150, uom: "" },
        { title: "Final Quantity", heading: "available_secondary", width: 150, uom: "" },
      ],
      product: {
        suppliers: [
          {
            id: 1,
            supplier_name: "Nestlé Global. ()",
            supplier_part: "Part",
            supplier_status: "Active",
            primary_sales_price: "48.00",
            secondary_purchase_price: "30.00",
            primary_uom: "CAN",
            secondary_uom: "CTN",
          },
        ],
      },
    };
  },
  methods: {
    formatedateTime(param) {
      return moment(param).format("DD/MM/YYYY ");
      
    },

    formatMoneyVal(money) {
      return formatMoney(money, {
        symbol: "RM",
        decimal: ".",
        thousand: ",",
        precision: 2,
        format: "%s%v",
      });
    },
  },
  computed: {
    mod_Suppliers() {
      return this.product.suppliers;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.product-row {
  margin: 0 !important;
}
</style>
